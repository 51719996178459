import React, { useEffect, useRef, useState } from 'react'
import {
  addDays,
  differenceInCalendarDays,
  format,
  isSameDay,
  isValid,
  parse,
} from 'date-fns'
import { DayPicker, Matcher } from 'react-day-picker'

import { UTCDate } from '@date-fns/utc'
import { CalendarDaysIcon } from '@heroicons/react/24/outline'
import {
  Input,
  Popover,
  PopoverContent,
  PopoverHandler,
} from '@material-tailwind/react'

interface DatePickerProps {
  onChange: (date?: Date) => void
  value?: Date
  label?: string
  disabledDate?: (current: Date) => boolean
  disabled?: boolean
  allowClear?: boolean
  minDate?: Date
  maxDate?: Date
  enabledDates?: Date[]
  setParentDismiss?: (isOpen: boolean) => void
}

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  label,
  disabled = false,
  minDate,
  maxDate,
  enabledDates = [],
  setParentDismiss,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(value)
  const [month, setMonth] = useState<Date | undefined>(value)
  const [inputValue, setInputValue] = useState<string>()
  const [error, setError] = useState<string>('')

  const popoverRef = useRef<HTMLDivElement>(null)

  const isWithinRange = (date: Date) =>
    (!minDate || date >= minDate) && (!maxDate || date <= maxDate)

  const generateDisabledDates = (): Matcher[] => {
    const disabledDates: Matcher[] = []
    if (minDate) {
      disabledDates.push({ before: minDate })
    }
    if (maxDate) {
      disabledDates.push({ after: maxDate })
    }

    if (enabledDates.length) {
      const sortedDates = [...enabledDates].sort(
        (a, b) => a.getTime() - b.getTime()
      )
      const [startDate, endDate] = [
        sortedDates[0],
        sortedDates[sortedDates.length - 1],
      ]

      disabledDates.push({ before: startDate }, { after: endDate })

      const allDates: Date[] = Array.from(
        { length: differenceInCalendarDays(endDate, startDate) + 1 },
        (_, i) => addDays(startDate, i)
      )

      const filteredDisabledDates = allDates.filter(
        date => !enabledDates.some(enabledDate => isSameDay(date, enabledDate))
      )

      disabledDates.push(...filteredDisabledDates)
    }

    return disabledDates
  }

  const togglePopover = () => setIsOpen(prev => !prev)

  const handleSelect = (date: Date | undefined) => {
    if (date) {
      setError('')
      setSelectedDate(date)
      setInputValue(format(date, 'yyyy-MM-dd'))
      setMonth(date)
      onChange(date)
    } else {
      setError('Invalid Selection')
      setSelectedDate(undefined)
      setInputValue('')
      onChange(undefined)
    }
    setIsOpen(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9-]/g, '')

    // Ignore input beyond 10 characters
    if (value.length > 10) {
      return
    }

    setError('')
    setInputValue(value)

    // Proceed only when the input matches the full date
    if (value.length !== 10) {
      setSelectedDate(undefined)
      onChange(undefined)
      return
    }

    const parsedDate = parse(value, 'yyyy-MM-dd', new UTCDate())

    if (!isValid(parsedDate)) {
      setError('Invalid Date')
      return
    }

    if (!isWithinRange(parsedDate)) {
      setError('Date out of range')
      setSelectedDate(undefined)
      onChange(undefined)
      return
    }

    setSelectedDate(parsedDate)
    setMonth(parsedDate)
    onChange(parsedDate)
  }

  useEffect(() => {
    if (value && isValid(value) && isWithinRange(value)) {
      setInputValue(format(value, 'yyyy-MM-dd'))
      setSelectedDate(value)
    }
  }, [value])

  useEffect(() => {
    if (setParentDismiss) {
      setParentDismiss(isOpen)
    }
    isOpen && setMonth(value)
  }, [isOpen, setParentDismiss])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div>
      <Popover placement="bottom" open={isOpen}>
        <PopoverHandler>
          <div>
            <Input
              value={inputValue}
              onChange={handleInputChange}
              placeholder="YYYY-MM-DD"
              label={label}
              disabled={disabled}
              icon={
                disabled ? undefined : (
                  <CalendarDaysIcon
                    onClick={togglePopover}
                    className="cursor-pointer"
                  />
                )
              }
              crossOrigin={undefined}
              className={`${error ? 'text-red' : ''}`}
            />
            <div className="text-red text-xs">{error}</div>
          </div>
        </PopoverHandler>
        <PopoverContent className="z-[10000]" ref={popoverRef}>
          <DayPicker
            mode="single"
            month={month}
            onMonthChange={setMonth}
            selected={selectedDate}
            onSelect={handleSelect}
            disabled={generateDisabledDates()}
            className="border-0"
            classNames={{
              caption: 'flex justify-center py-2 mb-4 relative items-center',
              caption_label: 'text-lg font-medium text-gray-900',
              nav: 'flex items-center justify-between',
              nav_button:
                'h-6 w-6 hover:bg-blue-gray-50 p-1 rounded-md transition-colors',
              table: 'w-full border-collapse',
              head_row: 'font-medium text-gray-900',
              head_cell: 'w-9 text-sm',
              row: 'w-full mt-2',
              day: 'h-9 w-9 p-1 text-center',
              today: 'bg-cc-primary-gray-light rounded-full',
              selected: 'bg-cc-primary-orange text-white rounded-full',
              disabled: 'hover:cursor-not-allowed text-cc-primary-gray-light',
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default DatePicker
