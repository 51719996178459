import React from 'react'

import './next-button.css'

const Next = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="round" id="next-instruction" onClick={onClick}>
      <div className="arrow primera next ml-1"></div>
      <div className="arrow segunda next -ml-1"></div>
    </div>
  )
}

export default Next
