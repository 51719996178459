import React from 'react'

import { Typography } from '@material-tailwind/react'

export const setUpdateHelper = (prev: Set<any>, entity: string) => {
  const updatedSet = new Set(prev)
  if (updatedSet.has(entity)) {
    updatedSet.delete(entity)
  } else {
    updatedSet.add(entity)
  }
  return updatedSet
}

export const Toggler = ({
  option,
  title,
  toggleFunction,
  checkedSet,
  description,
}: {
  option: string
  title: string
  toggleFunction: (option: string) => void
  checkedSet: Set<string>
  description?: string
}) => {
  return (
    <div key={option} className="flex gap-5">
      <label className="switch">
        <input
          type="checkbox"
          checked={checkedSet.has(option)}
          onChange={() => {
            toggleFunction(option)
          }}
        />
        <span className="slider"></span>
      </label>
      <div>
        <Typography className="font-bold text-base">{title}</Typography>
        {description ? (
          <Typography className="font-light text-sm">{description}</Typography>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  )
}
