import React from 'react'

const Progress = ({
  step,
  totalSteps,
  navigationFn,
  closeFn,
}: {
  step: number
  totalSteps: number
  navigationFn: (s: number) => void
  closeFn: () => void
}) => {
  const progress = []
  for (let i = 0; i < totalSteps; i++) {
    progress.push(
      <div
        className={`border ${
          i <= step
            ? 'bg-cc-icon-secondary border-cc-icon-secondary'
            : 'border-white'
        } h-8 w-8 rounded-full flex items-center justify-center text-white cursor-pointer`}
        onClick={() => navigationFn(i)}
      >
        {i + 1}
      </div>
    )
    i < totalSteps - 1 &&
      progress.push(
        <div
          className={`h-[2px] w-8 flex-1 transition-all duration-800 ${
            i < step ? 'bg-cc-icon-secondary' : ''
          }`}
        />
      )
  }
  progress.push(<div className={`h-[2px] w-8 flex-1`} />)
  progress.push(
    <div
      className={`border border-white
            h-8 px-4 rounded-full flex items-center justify-center text-white cursor-pointer hover:bg-cc-primary-orange hover:border-cc-primary-orange transition-colors duration-800`}
      onClick={() => closeFn()}
    >
      Skip
    </div>
  )
  return (
    <div className="absolute bottom-9 left-1/2 -translate-x-1/2 z-[999] flex items-center">
      {progress}
    </div>
  )
}

export default Progress
