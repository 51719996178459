import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useFlaggedRoutes } from '@components/app-routes/routes'
import NewFeature from '@components/feature-instructions/new-feature-icon'
import AuthContext from '@contexts/auth'
import { IconButton, Tooltip } from '@material-tailwind/react'
interface SidebarProps {
  /**
   * whether L1 navigation is expanded or not
   */
  expanded: boolean
}

const IconTemplate = ({
  features,
  expanded,
  className,
}: {
  features: any
  expanded: SidebarProps['expanded']
  className?: string
}) => {
  return (
    <div
      className={`${className ? className : ''} ${
        expanded ? 'visible' : 'invisible'
      } lg:visible flex flex-col items-center gap-4`}
      id="sidebar-menu"
    >
      {features}
    </div>
  )
}

const Sidebar = ({ expanded }: SidebarProps) => {
  const [visitedIcon, setVisitedIcon] = useState<Set<string>>(new Set())

  const navigate = useNavigate()
  const location = useLocation()

  const context = useContext(AuthContext)

  const getFlaggedRoutes = useFlaggedRoutes(context, true)
  const flaggedRouting = getFlaggedRoutes()

  const renderRoute = (r: any) => {
    {
      const isActive = location.pathname.includes(r.path)
      const Icon = isActive ? r.activeIcon : r.icon
      return (
        <Tooltip key={r.path} content={r.title} placement="right">
          <IconButton
            variant="text"
            className={`w-[50px]  !important h-[50px] !important hover:bg-cc-secondary-hover-deselected transition-none rounded-md ${
              isActive ? 'bg-cc-secondary-hover-selected' : ''
            }`}
            onClick={() => {
              navigate(r.path)
              setVisitedIcon(prev => new Set([Array.from(prev), r.title]))
            }}
          >
            <Icon className="w-6 text-primary-main" />
          </IconButton>
        </Tooltip>
      )
    }
  }

  const applications = flaggedRouting
    .filter(r => r.path !== 'notification')
    .map(r => renderRoute(r))

  const notification = flaggedRouting
    .filter(r => r.path === 'notification')
    .map(r => renderRoute(r))

  return (
    <div
      className={`bg-neutral-white flex flex-col  w-sidebar h-full z-13 py-6 gap-5 z-10`}
    >
      <div className=" flex justify-center items-center">
        <img
          src="/svgs/cascade-logo.svg"
          alt="Cascade"
          data-testid="cascade-logo"
          className="w-[35px] h-[35px]"
        />
      </div>
      <IconTemplate features={applications} expanded={expanded} />
      <div className="mt-auto -mb-2 relative">
        <IconTemplate features={notification} expanded={expanded} />
        {!visitedIcon.has('Notification Center') && (
          <NewFeature localTag="hasSeenNotification" className="" />
        )}
      </div>
    </div>
  )
}

export default Sidebar
