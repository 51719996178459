import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import UsersPage from 'src/pages/administration/setting/users'
import LoantapeOverviewL3 from 'src/pages/analytics/data-exports/loantape'
import CharacteristicPage from 'src/pages/analytics/risk/characteristic'
import CohortPage from 'src/pages/analytics/risk/cohort'
import CollectionPage from 'src/pages/analytics/risk/collection'
import DelinquencyPage from 'src/pages/analytics/risk/delinquency'
import RiskIndicatorsL3 from 'src/pages/analytics/risk/indicators'
import RiskOverviewL3 from 'src/pages/analytics/risk/overview'
import TractionPage from 'src/pages/analytics/risk/traction'
import MonitorOverviewL3 from 'src/pages/manage/monitor/overview'

import {
  DataValidationIconOutline,
  DataValidationIconSolid,
} from '@components/other/data-validation-icon'
import { Role } from '@constants/role'
import { AuthContextValues } from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import {
  BellAlertIcon,
  ChartBarIcon,
  PencilSquareIcon,
  Squares2X2Icon as ViewGridIcon,
} from '@heroicons/react/24/outline'
import {
  BellAlertIcon as BellAlertSolid,
  ChartBarIcon as ChartBarSolid,
  PencilSquareIcon as PencilSquareSolid,
  Squares2X2Icon as ViewGridSolid,
} from '@heroicons/react/24/solid'
import { FeatureFlag } from '@interfaces/control'
import { CovenantType } from '@interfaces/manage-monitor-covenant'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import EntitiesTemplatesOverviewL3 from '@pages/administration/setting/accounting-config'
import AccountingOverviewL3 from '@pages/administration/sources/accounting'
import BankOverviewL3 from '@pages/administration/sources/banks'
import UploadsOverviewL3 from '@pages/administration/sources/uploads'
import KeyIndicatorsL3 from '@pages/analytics/financials/key-indicators'
import FinancialOverviewL3 from '@pages/analytics/financials/overview'
import StatementsL3 from '@pages/analytics/financials/statements'
import TestsL3 from '@pages/data-validation/quarantine/tests'
import ValidationsL3 from '@pages/data-validation/quarantine/validations'
import PaymentAuditL3 from '@pages/manage/audit/payment'
import UnderwritingAuditL3 from '@pages/manage/audit/underwriting'
import ActionsL3 from '@pages/manage/monitor/actions'
import MonitorBorrowingBaseL3 from '@pages/manage/monitor/borrowing-base'
import CashflowsL3 from '@pages/manage/monitor/cashflows'
import MonitorCovenantL3 from '@pages/manage/monitor/covenant'
import MonitorDashboardL3 from '@pages/manage/monitor/dashboard'
import NotificationLogs from '@pages/notification/notifications'
import AdminControlService from '@services/api-admin/control'

import features, { initialFeatureFlagMapping } from './features/features'
export interface RoutesProps {
  /**
   * used as routing path
   */
  path: string
  /**
   * used as navigation title
   */
  title: string
  /**
   * used as navigation icon
   */
  icon?: any
  /**
   * used as navigation icon while `path is active
   */
  activeIcon?: any
  /**
   * whether this route hidden or not from side menu
   */
  menuHidden?: boolean
  /**
   * component that will be rendered. Only for latest child
   */
  component?: any
  /**
   * nested routing
   */
  routes?: RoutesProps[]
  /**
   * function to validate whether current user is authorized for menu
   */
  isAuthorized?: (
    role: Role,
    slug?: string,
    context?: AuthContextValues,
    facility_id?: number
  ) => boolean
  /**
   * level order
   */
  order?: number
  /**
   * route access
   */
  access?: string[]
  /**
   * route access specific for external user. if not available fall back to access
   */
  access_external?: string[]
}

const manageModuleFeatureCheck = (
  feature: string,
  context?: AuthContextValues,
  facility_id?: number
) => {
  const location = useLocation()
  const { company, appliedFilters, optionFilters } = context ?? {}
  const params = new URLSearchParams(location.search)

  // allows redirect to a different company from a master facility
  if (company?.slug_name !== params.get('pid')) {
    return true
  }

  const { activeFacilityId } = appliedFilters ?? {}
  const { facilities = [] } = optionFilters ?? {}
  const activeFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === (facility_id ?? activeFacilityId)
  )
  const default_features =
    'overview, dashboard, borrowing-base, portfolio-covenants, financial-covenants, cashflows, actions'

  return (activeFacility?.features ?? default_features).includes(feature)
}

const Routes: RoutesProps[] = [
  {
    path: 'analytics',
    title: 'Analytics',
    icon: ChartBarIcon,
    activeIcon: ChartBarSolid,
    order: 2,
    routes: [
      {
        path: 'risk',
        title: 'Risk',
        isAuthorized: (_r, _s, context) => context?.company?.has_risk ?? false,
        routes: [
          {
            path: 'overview',
            title: 'Overview',
            menuHidden: false,
            access: ['access'],
            component: <RiskOverviewL3 />,
          },
          {
            path: 'indicators',
            title: 'Key Indicators',
            access: ['access'],
            component: <RiskIndicatorsL3 />,
          },
          {
            path: 'traction',
            title: 'Traction',
            component: <TractionPage />,
            routes: [
              {
                path: 'volume',
                title: 'Volume',
                routes: [
                  {
                    path: 'monthly',
                    title: 'Monthly',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'total',
                    title: 'Total',
                    access: ['access', 'export'],
                  },
                ],
              },
              {
                path: 'clients',
                title: 'Clients',
                routes: [
                  {
                    path: 'monthly-active-clients',
                    title: 'Monthly Active Clients',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'total-unique-clients',
                    title: 'Total Unique Clients',
                    access: ['access', 'export'],
                  },
                ],
              },
              {
                path: 'balance',
                title: 'Balance',
                routes: [
                  {
                    path: 'balance-volume',
                    title: 'Balance Volume',
                    access: ['access', 'export'],
                  },
                  {
                    path: '#-of-loans',
                    title: '# of Loans',
                    access: ['access', 'export'],
                  },
                ],
              },
            ],
          },
          {
            path: 'delinquency',
            title: 'Delinquency',
            component: <DelinquencyPage />,
            routes: [
              {
                path: 'portfolio-at-risk',
                title: 'Portfolio at Risk',
                access: ['access', 'export'],
              },
              {
                path: 'rolling-default-rate',
                title: 'Rolling Default Rate',
                access: ['access', 'export'],
              },
              {
                path: 'outstanding-by-delinquency',
                title: 'Outstanding by Delinquency',
                access: ['access', 'export'],
              },
              {
                path: 'first-payment-default(fpd)-by-cohort',
                title: 'First Payment Default(FPD) by Cohort',
                access: ['access', 'export'],
              },
              {
                path: 'borrower-concentration',
                title: 'Borrower Concentration',
                access: ['access', 'export'],
              },
              {
                path: 'delinquency-by-cohort',
                title: 'Delinquency by Cohort',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'collection',
            title: 'Collection',
            component: <CollectionPage />,
            routes: [
              {
                path: 'collection-rate',
                title: 'Collection Rate',
                access: ['access', 'export'],
              },
              {
                path: 'prepayment-rate-by-cohort',
                title: 'Prepayment Rate by Cohort',
                access: ['access', 'export'],
              },
              {
                path: 'collection-by-status',
                title: 'Collection by Status',
                access: ['access', 'export'],
              },
              {
                path: 'net-yield',
                title: 'Net Yield',
                access: ['access', 'export'],
              },
              {
                path: 'aggregated-roll-rates',
                title: 'Aggregated Roll Rates',
                access: ['access', 'export'],
              },
              {
                path: 'cash-collected-by-cohort',
                title: 'Cash Collected by Cohort',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'cohort',
            title: 'Cohort',
            component: <CohortPage />,
            routes: [
              {
                path: 'vintage-analysis',
                title: 'Vintage Analysis',
                access: ['access', 'export'],
              },
              {
                path: 'roll-rates',
                title: 'Roll Rates',
                access: ['access', 'export'],
              },
              {
                path: 'roll-rates-by-cohort',
                title: 'Roll Rates by Cohort',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'characteristics',
            title: 'Characteristics',
            component: <CharacteristicPage />,
            routes: [
              {
                path: 'interest-rate',
                title: 'Interest Rate by Cohort',
                routes: [
                  {
                    path: 'distribution',
                    title: 'Distribution',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'average',
                    title: 'Average',
                    access: ['access', 'export'],
                  },
                ],
              },
              {
                path: 'term',
                title: 'Term by Cohort',
                routes: [
                  {
                    path: 'distribution',
                    title: 'Distribution',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'average',
                    title: 'Average',
                    access: ['access', 'export'],
                  },
                ],
              },
              {
                path: 'value',
                title: 'Value by Cohort',
                routes: [
                  {
                    path: 'distribution',
                    title: 'Distribution',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'average',
                    title: 'Average',
                    access: ['access', 'export'],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'financials',
        title: 'Financials',
        isAuthorized: (_r, _s, context) =>
          context?.company?.has_financials ?? false,
        routes: [
          {
            path: 'overview',
            title: 'Overview',
            menuHidden: false,
            component: <FinancialOverviewL3 />,
            access: ['access'],
          },
          {
            path: 'key-indicators',
            title: 'Key Indicators',
            component: <KeyIndicatorsL3 />,
            routes: [
              {
                path: 'net-income',
                title: 'Net Income',
                access: ['access', 'export'],
              },
              {
                path: 'cash',
                title: 'Cash',
                routes: [
                  {
                    path: 'cash',
                    title: 'Cash',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'bank-account-distribution',
                    title: 'Bank Account Distribution',
                    access: ['access'],
                  },
                ],
              },
              {
                path: 'net-worth',
                title: 'Net Worth',
                access: ['access', 'export'],
              },
              {
                path: 'runway',
                title: 'Runway',
                access: ['access', 'export'],
              },
              {
                path: 'debt:equity',
                title: 'Debt:Equity',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'statements',
            title: 'Statements',
            component: <StatementsL3 />,
            routes: [
              {
                path: 'balance-sheet',
                title: 'Balance Sheet',
                access: ['access', 'export'],
              },
              {
                path: 'profit-&-loss',
                title: 'Profit Loss',
                access: ['access', 'export'],
              },
            ],
          },
        ],
      },

      {
        path: 'data-exports',
        title: 'Data Exports',
        isAuthorized: (_r, _s, context) => context?.company?.has_risk ?? false,
        routes: [
          {
            path: 'loan-tape',
            title: 'Loan Tape',
            access: ['access', 'export'],
            component: <LoantapeOverviewL3 />,
          },
        ],
      },
    ],
  },
  {
    path: 'manage',
    title: 'Manage',
    icon: PencilSquareIcon,
    activeIcon: PencilSquareSolid,
    order: 3,
    routes: [
      {
        path: 'monitor',
        title: 'Monitor',
        routes: [
          {
            path: 'overview',
            title: 'Overview',
            menuHidden: false,
            component: <MonitorOverviewL3 />,
            access: ['access'],
          },
          {
            path: 'dashboard',
            title: 'Dashboard',
            component: <MonitorDashboardL3 />,
            isAuthorized: (_r, _s, context, facility_id) =>
              manageModuleFeatureCheck('dashboard', context, facility_id),
            access: ['access', 'export'],
          },
          {
            path: 'borrowing-base',
            title: 'Borrowing Base',
            component: <MonitorBorrowingBaseL3 />,
            isAuthorized: (_r, _s, context, facility_id) =>
              manageModuleFeatureCheck('borrowing-base', context, facility_id),
            routes: [
              {
                path: 'borrowing-base',
                title: 'Borrowing Base',
                access: ['access', 'export'],
              },
              {
                path: 'collateral',
                title: 'Collateral',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'portfolio-covenants',
            title: 'Portfolio Covenants',
            component: <MonitorCovenantL3 type={CovenantType.portfolio} />,
            isAuthorized: (_r, _s, context, facility_id) =>
              manageModuleFeatureCheck(
                'portfolio-covenants',
                context,
                facility_id
              ),
            access: ['access', 'export'],
          },
          {
            path: 'financial-covenants',
            title: 'Financial Covenants',
            component: <MonitorCovenantL3 type={CovenantType.financial} />,
            isAuthorized: (_r, _s, context, facility_id) =>
              manageModuleFeatureCheck(
                'financial-covenants',
                context,
                facility_id
              ),
            access: ['access', 'export'],
          },
          {
            path: 'cashflows',
            title: 'Cashflows',
            component: <CashflowsL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('cashflows', context),
            routes: [
              {
                path: 'payment-schedule',
                title: 'Payment Schedule',
                access: ['access'],
              },
            ],
          },
          {
            path: 'actions',
            title: 'Actions',
            component: <ActionsL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('actions', context),
            routes: [
              {
                path: 'document-centre',
                title: 'Document Centre',
                access: ['access', 'create', 'sign'],
              },
              {
                path: 'waterfall',
                title: 'Waterfall',
                access: ['access', 'create', 'sign'],
              },
              {
                path: 'advance-request',
                title: 'Advance Request',
                access: ['access', 'create', 'sign'],
              },
            ],
          },
        ],
      },
      {
        path: 'audit',
        title: 'Audit',
        isAuthorized: (_r, _s, context) =>
          manageModuleFeatureCheck('-audit', context),
        routes: [
          {
            path: 'payment-audit',
            title: 'Payment Audit',
            component: <PaymentAuditL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('payment-audit', context),
            access: ['access', 'upload', 'approve/reject', 'export'],
          },
          {
            path: 'underwriting-audit',
            title: 'Underwriting Audit',
            component: <UnderwritingAuditL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('underwriting-audit', context),
            access: ['access', 'upload', 'approve/reject', 'export'],
          },
        ],
      },
    ],
  },
  {
    path: 'administration',
    title: 'Administration',
    icon: ViewGridIcon,
    activeIcon: ViewGridSolid,
    order: 1,
    routes: [
      {
        path: 'sources',
        title: 'Data Sources',
        isAuthorized: (role: Role) =>
          [Role.superadmin, Role.admin, Role.custom].includes(role),
        routes: [
          {
            path: 'banking',
            title: 'Banking',
            component: <BankOverviewL3 />,
            access: ['access', 'create', 'edit'],
          },
          {
            path: 'accounting',
            title: 'Accounting',
            component: <AccountingOverviewL3 />,
            access: ['access', 'create', 'edit'],
            isAuthorized: (_r, _s, context) =>
              context?.company?.has_financials ?? false,
          },
          {
            path: 'uploads',
            title: 'Historical Uploads',
            component: <UploadsOverviewL3 />,
            access: ['access', 'create', 'edit'],
            isAuthorized: (_r, _s, context) =>
              context?.company?.has_financials ?? false,
          },
        ],
      },
      {
        path: 'setting',
        title: 'Settings',
        routes: [
          {
            path: 'accounting-config',
            title: 'Accounting Config',
            component: <EntitiesTemplatesOverviewL3 />,
            access: ['access', 'create', 'edit', 'delete'],
            isAuthorized: (role: Role, _s, context) =>
              [Role.superadmin].includes(role) &&
              (context?.company?.has_financials ?? false),
          },
          {
            path: 'users',
            title: 'Users',
            component: <UsersPage />,
            access: ['access', 'create', 'edit', 'delete'],
            isAuthorized: (role: Role) =>
              [Role.superadmin, Role.admin, Role.custom].includes(role),
          },
        ],
      },
    ],
  },
  {
    path: 'data-validation',
    title: 'Data Validation',
    icon: DataValidationIconOutline,
    activeIcon: DataValidationIconSolid, //
    order: 4,
    routes: [
      {
        path: 'quarantine',
        title: 'Quarantine',
        isAuthorized: (role: Role) =>
          [Role.superadmin, Role.admin, Role.custom].includes(role),
        routes: [
          {
            path: 'tests',
            title: 'Validation Tests',
            component: <TestsL3 />,
            access: ['access', 'create', 'edit'],
          },
          {
            path: 'validations',
            title: 'Results',
            component: <ValidationsL3 />,
            access: ['access', 'create', 'edit'],
          },
        ],
      },
    ],
  },
  {
    path: 'notification',
    title: 'Notification Center',
    icon: BellAlertIcon,
    activeIcon: BellAlertSolid, //
    order: 5,
    routes: [
      {
        path: 'notifications',
        title: 'Notifications',
        isAuthorized: (role: Role) =>
          [Role.superadmin, Role.admin, Role.custom].includes(role),
        routes: [
          {
            path: 'logs',
            title: 'Logs',
            menuHidden: false,
            component: <NotificationLogs />,
            access: ['access', 'create', 'edit'],
          },
        ],
      },
    ],
  },
]

/**
 *
 * @param   {string[]}      auth0Features   Auth0 user's features
 * @returns {string[]}                      Available features
 */
export const AvailableFeatures = (auth0Features?: string[]) => {
  const availableFeatures: string[] =
    auth0Features && auth0Features?.length > 0 ? auth0Features : features

  return availableFeatures
}

const check_accessibility = (
  context: AuthContextValues,
  path: string,
  featureFlagMapping: FeatureFlag[],
  facility_id?: number,
  isAuthorized?: (
    role: Role,
    slug?: string,
    context?: AuthContextValues,
    facility_id?: number
  ) => boolean
) => {
  const { userMetadata, company } = context
  const isSuperadmin = userMetadata?.isSuperadmin
  const userAccess = userMetadata?.companies?.[company?.slug_name ?? '']
  const defaulfRole = Role.staff
  const activeRole = isSuperadmin
    ? Role.superadmin
    : userAccess?.control ?? defaulfRole
  const activeAccess = userAccess?.access ?? {}

  const hasAuthority = isAuthorized
    ? isAuthorized(activeRole, company?.slug_name, context, facility_id)
    : true

  /**
   * Checks if a corresponding feature flag exists and is available.
   * defaults to true when a corresponding feature flag is not explicitly
   * available.
   * If a path has no corresponding feature flag with it is treated as a
   * default path and is turned on
   */
  const pathFeatureFlagEnabled =
    featureFlagMapping.find((f: { path: string }) => f.path == path)?.control ??
    true

  const isAvailableFeature =
    !!AvailableFeatures(Object.keys(activeAccess)).find(x =>
      x.includes(path)
    ) && pathFeatureFlagEnabled

  const isAvailableAccess =
    activeRole === Role.custom
      ? Object.keys(activeAccess).filter(
          aa => aa.includes(path) && activeAccess[aa].includes('access')
        ).length > 0
      : true

  const isAvailable = hasAuthority && isAvailableFeature && isAvailableAccess
  return isAvailable
}

export const useFlaggedRoutes = (
  context: AuthContextValues,
  sort = false
): ((facility_id?: number) => RoutesProps[]) => {
  const { company } = context
  const [featureFlagMapping, setFeatureFlagMapping] = useState(
    initialFeatureFlagMapping
  )

  /**
   * Fetch feature flags and update the control values on success
   * * * * *
   * Maintain with all other module's control services
   * to obtain feature flag info
   * * * * *
   * admin
   */
  const { data: featureFlagData } = useQuery(
    ['allFeatureFlags', company],
    () => {
      const slugName = company?.slug_name
      if (slugName) {
        return AdminControlService.getFeatureFlags(slugName)
      }
    },
    getStaleMins()
  )

  useEffect(() => {
    if (featureFlagData) {
      updateFeatureFlagControl(featureFlagData)
    }
  }, [featureFlagData])

  const updateFeatureFlagControl = (data: any) => {
    const updatedMapping = initialFeatureFlagMapping.map(
      (feature: FeatureFlag) => ({
        ...feature,
        control: data?.data[feature.flag.toLowerCase()] ?? feature.control,
      })
    )
    setFeatureFlagMapping(updatedMapping)
  }

  /**
   * we're currently assuming every company has access to administration, analytic module by default
   */
  const modules: { [key: string]: boolean } = {
    administration: true,
    analytics: company?.has_analytics ?? true,
    manage: company?.has_manage ?? false,
    raise: company?.has_raise ?? false,
    // just true atm - tony 09/17
    ['data-validation']: true,
    // just true atm - tony 10/23
    ['notification']: true,
  }

  const getFlaggedRoutes = (facility_id?: number): RoutesProps[] => {
    const routing = Routes.filter(l1 => {
      const path = l1.path
      const is_available = check_accessibility(
        context,
        path,
        featureFlagMapping,
        facility_id,
        l1.isAuthorized
      )
      return is_available && modules?.[path]
    })
      .map(l1 => {
        const l2 = l1.routes
          ?.filter(l2 => {
            const path = `${l1.path}_${l2.path}`
            const is_available = check_accessibility(
              context,
              path,
              featureFlagMapping,
              facility_id,
              l2.isAuthorized
            )
            return is_available
          })
          .map(l2 => {
            const l3 = l2.routes?.filter(l3 => {
              const path = `${l1.path}_${l2.path}_${l3.path}`
              const is_available = check_accessibility(
                context,
                path,
                featureFlagMapping,
                facility_id,
                l3.isAuthorized
              )
              return is_available
            })
            return {
              ...l2,
              routes: l3,
            }
          })
          .filter(f => f.routes && f.routes.length > 0)
        return {
          ...l1,
          routes: l2,
        }
      })
      .filter(f => f.routes && f.routes.length > 0)

    return sort
      ? routing.sort((a, b) => ((a?.order ?? 0) < (b?.order ?? 0) ? -1 : 1))
      : routing
  }

  return getFlaggedRoutes
}
